import { useEffect, useState } from 'react';
import { MediaBreakPoint, MediaBreakPointUp } from "../theme";
var isClient = typeof window === 'object';

var isApiSupported = api => typeof window !== 'undefined' ? api in window : false;

var errorMessage = 'matchMedia is not supported, this could happen both because window.matchMedia is not supported by' + " your current browser or you're using the useMediaQuery hook whilst server side rendering.";
/**
 * Accepts a media query string then uses the
 * [window.matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia) API to determine if it
 * matches with the current document.<br />
 * It also monitor the document changes to detect when it matches or stops matching the media query.<br />
 * Returns the validity state of the given media query.
 *
 */

export var useMediaQuery = breakPoint => {
  var mediaQuery = "(max-width: ".concat(MediaBreakPointUp[breakPoint], ")");

  if (!isClient || !isApiSupported('matchMedia')) {
    console.warn(errorMessage);
    return null;
  }

  var _useState = useState(!!window.matchMedia(mediaQuery).matches),
      isVerified = _useState[0],
      setIsVerified = _useState[1];

  var documentChangeHandler = ev => {
    setIsVerified(!!ev.matches);
  };

  useEffect(() => {
    var mediaQueryList = window.matchMedia(mediaQuery);

    try {
      mediaQueryList.addEventListener('change', documentChangeHandler);
    } catch (e) {
      // Safari isn't supporting mediaQueryList.addEventListener
      mediaQueryList.addListener(documentChangeHandler);
    }

    return () => {
      try {
        mediaQueryList.removeEventListener('change', documentChangeHandler);
      } catch (error) {
        mediaQueryList.removeListener(documentChangeHandler);
      }
    };
  }, [mediaQuery]);
  return isVerified;
};
export var useBreakpoint = () => {
  var isBreakPointXL = useMediaQuery(MediaBreakPoint.XL);
  var isBreakPointLG = useMediaQuery(MediaBreakPoint.LG);
  var isBreakPointMD = useMediaQuery(MediaBreakPoint.MD);
  var isBreakPointSM = useMediaQuery(MediaBreakPoint.SM);
  return {
    isBreakPointLG,
    isBreakPointMD,
    isBreakPointSM,
    isBreakPointXL,
    isMobile: isBreakPointSM
  };
};
export default useMediaQuery;