import { useCallback, useEffect, useState } from 'react';
import { isClient } from "../utils/userAgent";
var VISIBILITY_STATE_SUPPORTED = isClient && 'visibilityState' in document;

function isWindowVisible() {
  return !VISIBILITY_STATE_SUPPORTED || document.visibilityState !== 'hidden';
}
/**
 * Returns whether the window is currently visible to the user.
 */


export var useIsWindowVisible = () => {
  var _useState = useState(isWindowVisible()),
      focused = _useState[0],
      setFocused = _useState[1];

  var listener = useCallback(() => {
    setFocused(isWindowVisible());
  }, [setFocused]);
  useEffect(() => {
    if (!VISIBILITY_STATE_SUPPORTED) return undefined;
    document.addEventListener('visibilitychange', listener);
    return () => {
      document.removeEventListener('visibilitychange', listener);
    };
  }, [listener]);
  return focused;
};