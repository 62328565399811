import styled from 'styled-components';
export var Row = styled.div.withConfig({
  displayName: "Row",
  componentId: "sc-n8a987-0"
})(["width:", ";display:flex;padding:0;flex-wrap:", ";align-items:", ";justify-content:", ";gap:", ";padding:", ";border:", ";margin:", ";border-radius:", ";background-color:", ";"], _ref => {
  var width = _ref.width;
  return width !== null && width !== void 0 ? width : '100%';
}, _ref2 => {
  var wrap = _ref2.wrap;
  return wrap !== null && wrap !== void 0 ? wrap : 'nowrap';
}, _ref3 => {
  var align = _ref3.align;
  return align !== null && align !== void 0 ? align : 'center';
}, _ref4 => {
  var justify = _ref4.justify;
  return justify !== null && justify !== void 0 ? justify : 'flex-start';
}, _ref5 => {
  var gap = _ref5.gap;
  return gap !== null && gap !== void 0 ? gap : 0;
}, _ref6 => {
  var padding = _ref6.padding;
  return padding;
}, _ref7 => {
  var border = _ref7.border;
  return border;
}, _ref8 => {
  var margin = _ref8.margin;
  return margin !== null && margin !== void 0 ? margin : 0;
}, _ref9 => {
  var borderRadius = _ref9.borderRadius;
  return borderRadius;
}, _ref10 => {
  var backgroundColor = _ref10.backgroundColor;
  return backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : 'transparent';
});
export var RowBetween = styled(Row).withConfig({
  displayName: "RowBetween",
  componentId: "sc-n8a987-1"
})(["justify-content:space-between;"]);
export var RowGap = styled(Row).withConfig({
  displayName: "RowGap",
  componentId: "sc-n8a987-2"
})(["flex-wrap:wrap;margin:", ";justify-content:", ";& > *{margin:", " !important;}"], _ref11 => {
  var gap = _ref11.gap;
  return gap && "-".concat(gap);
}, _ref12 => {
  var justify = _ref12.justify;
  return justify !== null && justify !== void 0 ? justify : 'flex-start';
}, _ref13 => {
  var gap = _ref13.gap;
  return gap;
});