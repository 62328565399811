import TokenWebView from '@consenlabs-fe/webview';
export var detectImTokenApp = () => TokenWebView.isTokenEnv();
export var isClient = typeof window !== 'undefined';
var imToken = typeof window !== 'undefined' ? window['imToken'] : null;
export function routeTo(payload) {
  return imToken.callPromisifyAPI('navigator.routeTo', payload).catch(err => console.warn(err));
}
export function goTokenlon(address) {
  if (TokenWebView.isTokenEnv()) {
    routeTo({
      screen: 'Tokenlon',
      passProps: {
        xChainReceiver: address
      }
    });
  } else {
    window.location.href = "".concat(window.location.origin, "/instant");
  }
}