import React from "react";
var __jsx = React.createElement;
import { useTranslation } from 'react-i18next';
import { useGA } from "../../hooks";
import { StyledAgreement } from "./styled";
export var Agreement = () => {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var _useGA = useGA(),
      trackElClick = _useGA.trackElClick;

  return __jsx(StyledAgreement, null, __jsx("span", {
    className: "agreement-info"
  }, __jsx("span", null, t('rules_label'), "\xA0"), __jsx("a", {
    href: "/tos",
    target: "_blank",
    "data-trackid": "webtl_walletconnect_agreement",
    onClick: trackElClick
  }, t('user_rules'))));
};