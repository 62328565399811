import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

/**
 * # 用来处理数字/浮点数相关的工具函数
 *
 * 统一使用类型为 `import type { BigNumber } from "BigNumber.js"` 的 BigNumber 来处理数字
 *
 * ## why not `JSBI`, `big.js`, `bn.js`, `decimals.js`, `jsbn` or native `BigInt`?
 *
 * `big.js`, `bignumber.js`, `decimals.js` 可以支持 小数 （浮点数），其他 lib 只能支持 整数。
 * 如果需要支持小数，需要自己操作 { amount: uint256, decimals: uint8 } 的结构，同时要处理除法精度问题，比较麻烦。
 * 这三个 lib 都是同一个作者实现的，三者的区别可以看作者的解释：<https://github.com/MikeMcl/big.js/wiki>
 * `big.js` 有最小的包体积和较完整的 API，但 `bignumber.js` 和 `decimals.js` 对大整数运算更快，并直接支持
 * 带`0x`前导的十六进制字符串。同时 `decimals.js` 在计算时会出现精度丢失的情况。
 *
 * 因为，我们最终选择 `bignumber.js` 作为项目的唯一数字/浮点数库。
 */
import BigNumber from 'bignumber.js';
BigNumber.config({
  ROUNDING_MODE: BigNumber.ROUND_DOWN
});
export var Currency;

(function (Currency) {
  Currency["CNY"] = "CNY";
  Currency["USD"] = "USD";
})(Currency || (Currency = {}));

export var CurrencySymbol = {
  [Currency.CNY]: '¥',
  [Currency.USD]: '$'
};
export class FixedPointNumberInterface extends BigNumber {
  constructor() {
    super(...arguments);

    _defineProperty(this, "amount", void 0);

    _defineProperty(this, "decimals", void 0);
  }

  /**
   * 将 BigNumber 对象，转换为指定有效数字的字符串
   *
   * 有效数字指科学计算中用以表示一定长度浮点数精度的那些数字。一般指一个用小数形式表示的浮点数中，
   * 从第一个非零的数字算起的所有数字，因此，1.24和0.00124的有效数字都有3位。并且在取有效数字时
   * 会遵循`四舍五入`的进位规则。
   *
   * @docs: [有效数字](https://zh.wikipedia.org/wiki/%E6%9C%89%E6%95%88%E6%95%B0%E5%AD%97)
   * @returns {string}
   */
  toSignificant(significantNumber) {
    return this.amount.shiftedBy(-this.decimals).sd(significantNumber).toString();
  }

  toDecimalsFixed(fixedAt) {
    var roundedMode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : BigNumber.ROUND_DOWN;
    return this.amount.shiftedBy(-this.decimals).toFixed(fixedAt, roundedMode);
  }

  toAutoFormat() {
    var significantNumber = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 4;
    var roundedMode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : BigNumber.ROUND_DOWN;
    var decimalToUnit = new BigNumber(this.toDecimalsFixed(this.decimals, roundedMode));
    if (decimalToUnit.gte(1)) return decimalToUnit.decimalPlaces(4).toFormat();
    return this.toSignificant(significantNumber);
  }

  toCurrency(currency) {
    var fixedAt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    var roundingMode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : BigNumber.ROUND_DOWN;
    var symbol = CurrencySymbol[currency];
    var value = this.toDecimalsFixed(fixedAt, roundingMode);
    return "".concat(symbol).concat(new BigNumber(value).toFormat());
  }

}
export class FixedPointNumber extends FixedPointNumberInterface {
  /**
   * 创建一个 FixedPoint 对象
   *
   * 为什么需要 FixedPoint 对象，而不是直接使用 BigNumber 对象？
   *
   * 1. 保留原始值，方便后续计算。
   * 2. 统一格式化输出
   *
   * @param {BigNumberGens} amount the original number value
   * @param {number} decimals fixed point number of decimals
   */
  constructor(_amount) {
    var _decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    super(_amount ? _amount.toString() : '');

    _defineProperty(this, "amount", new BigNumber(0));

    _defineProperty(this, "decimals", 0);

    this.amount = _amount ? new BigNumber(_amount.toString()) : new BigNumber(0);

    if (_amount instanceof FixedPointNumber && typeof _decimals === 'undefined') {
      _decimals = _amount.decimals;
    }

    this.decimals = Number(_decimals) || 0;
  }

  toString() {
    return this.amount.toString();
  }

}