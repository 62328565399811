import _slicedToArray from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React from "react";
var __jsx = React.createElement;
import { useEffect } from 'react';
import { useUpdaterChainId, useNetworkValidator, useLimitOrderRouter } from "../../hooks";
import { useAppDispatch } from "../hooks";
import { addLimitTokens, addSpotTokens, updateConfig, updateLimitSwapPair, updateSpotSwapPair } from "./actions";
import exchangeRpc from "../../services/rpc/tokenlon";
import { useLimitSwapPair, useLimitSwapTokens, useLimitSwapTokensUpdater, useSpotSwapPair, useSpotSwapTokens, useSpotSwapTokensUpdater } from "./hooks";
import { USDC, WETH, ETH, USDT, ARB } from "../../constants/addresses";
import { getOppositesByToken } from "../../utils/token";

var findTokenByAddress = (address, tokens) => {
  var lowercaseAddress = address.toLowerCase();

  for (var key in tokens) {
    if (key.toLowerCase() === lowercaseAddress) {
      return tokens[key];
    }
  }

  return undefined;
};

export var SpotSwapPairResetUpdater = () => {
  var dispatch = useAppDispatch();
  var chainId = useUpdaterChainId();

  var _useSpotSwapPair = useSpotSwapPair(),
      _useSpotSwapPair2 = _slicedToArray(_useSpotSwapPair, 2),
      inputToken = _useSpotSwapPair2[0],
      outputToken = _useSpotSwapPair2[1];

  var tokens = useSpotSwapTokens(chainId);
  useEffect(() => {
    if (!tokens || !Object.keys(tokens).length) return;

    if (!inputToken || !outputToken || !tokens[inputToken.address] || !tokens[outputToken.address]) {
      var defaultInputToken = findTokenByAddress(ETH[chainId], tokens);
      var defaultOutputToken = findTokenByAddress(USDT[chainId], tokens);

      if (!defaultInputToken || !defaultOutputToken) {
        var addresses = Object.keys(tokens);
        defaultInputToken = tokens[addresses[0]];
        var takerTokenList = getOppositesByToken(Object.values(tokens), defaultInputToken);
        defaultOutputToken = takerTokenList[0];
      }

      dispatch(updateSpotSwapPair({
        inputToken: defaultInputToken,
        outputToken: defaultOutputToken
      }));
    }
  }, [dispatch, tokens, inputToken, outputToken]);
  return null;
};
export var LimitSwapPairResetUpdater = () => {
  var dispatch = useAppDispatch();
  var chainId = useUpdaterChainId();

  var _useLimitSwapPair = useLimitSwapPair(),
      _useLimitSwapPair2 = _slicedToArray(_useLimitSwapPair, 2),
      inputToken = _useLimitSwapPair2[0],
      outputToken = _useLimitSwapPair2[1];

  var tokens = useLimitSwapTokens(chainId);

  var _useNetworkValidator = useNetworkValidator(),
      isArbitrum = _useNetworkValidator.isArbitrum;

  useEffect(() => {
    if (!tokens || !Object.keys(tokens).length) return;

    if (!inputToken || !outputToken || !tokens[inputToken.address] || !tokens[outputToken.address]) {
      var defaultInputToken = isArbitrum ? findTokenByAddress(USDC[chainId], tokens) : findTokenByAddress(WETH[chainId], tokens);
      var defaultOutputToken = isArbitrum ? findTokenByAddress(ARB[chainId], tokens) : findTokenByAddress(USDT[chainId], tokens);

      if (!defaultInputToken || !defaultOutputToken) {
        var addresses = Object.keys(tokens);
        defaultInputToken = tokens[addresses[0]];
        defaultOutputToken = tokens[addresses[1]];
      }

      dispatch(updateLimitSwapPair({
        inputToken: defaultInputToken,
        outputToken: defaultOutputToken
      }));
    }
  }, [dispatch, tokens, inputToken, outputToken]);
  return null;
};
export var DexUpdater = () => {
  var chainId = useUpdaterChainId();
  var dispatch = useAppDispatch();
  var isLimitOrderRouter = useLimitOrderRouter();
  var updateSpotSwapTokens = useSpotSwapTokensUpdater();
  var updateLimitSwapTokens = useLimitSwapTokensUpdater();
  useEffect(() => {
    if (!chainId) return;

    if (isLimitOrderRouter) {
      updateLimitSwapTokens().then(tokens => tokens && tokens.length && dispatch(addLimitTokens({
        tokens,
        chainId
      })));
    } else {
      updateSpotSwapTokens().then(tokens => tokens && tokens.length && dispatch(addSpotTokens({
        tokens,
        chainId
      })));
    }

    exchangeRpc.getMobileAppConfig().then(config => {
      dispatch(updateConfig({
        chainId,
        config
      }));
    });
  }, [chainId, dispatch, updateSpotSwapTokens, updateLimitSwapTokens, isLimitOrderRouter]);
  return __jsx(React.Fragment, null, __jsx(SpotSwapPairResetUpdater, null), __jsx(LimitSwapPairResetUpdater, null));
};
export default DexUpdater;