import React from "react";
var __jsx = React.createElement;
import { useWeb3React } from '@web3-react/core';
import { useInterfaceMulticall } from "../../hooks/useContract";
import { useBlockNumber } from "../application/hooks";
import { multicall } from "./instance"; // Create Updater wrappers that pull needed info from store

export default function Updater() {
  var latestBlockNumber = useBlockNumber();

  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId;

  var multicall2Contract = useInterfaceMulticall();
  return __jsx(multicall.Updater, {
    chainId: chainId,
    latestBlockNumber: latestBlockNumber,
    contract: multicall2Contract
  });
}