/**
 * for persist serialize, use string as Wallet Type Symbol
 * instead of Connector Instance
 */
export var WalletType;

(function (WalletType) {
  WalletType["IMTOKEN_CALL_APP"] = "imToken Call App";
  WalletType["IMTOKEN_WALLET_CONNECT"] = "imToken Wallet Connect";
  WalletType["IMTOKEN_INJECTED"] = "imToken Injected";
  WalletType["INJECTED"] = "MetaMask";
  WalletType["METAMASK_WALLET_CONNECT"] = "MetaMask Wallet Connect";
  WalletType["METAMASK_MOBILE"] = "METAMASK_MOBILE";
  WalletType["WALLET_CONNECT"] = "WalletConnect";
  WalletType["IMKEY"] = "imKey";
  WalletType["UNSTOPPABLE_DOMAIN"] = "Unstoppable Domain";
  WalletType["COINBASE"] = "Coinbase";
})(WalletType || (WalletType = {}));