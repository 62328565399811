import { useEffect, useState } from 'react'; // modified from https://usehooks.com/useDebounce/

export var useDebounce = (value, delay) => {
  var _useState = useState(value),
      debouncedValue = _useState[0],
      setDebouncedValue = _useState[1];

  useEffect(() => {
    // Update debounced value after delay
    var handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay); // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is changed ...
    // .. within the delay period. Timeout gets cleared and restarted.

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};