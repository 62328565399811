import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from "../hooks";
import { addTransaction, TransactionType } from "./actions";
import { useWeb3React } from '@web3-react/core';
export var useAllTransactions = () => {
  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId;

  return useAppSelector(state => state.transactions[chainId] || {});
};
export var useTransaction = txHash => {
  var allTransactions = useAllTransactions();
  if (!txHash) return undefined;
  return allTransactions[txHash];
};
export var useTransactionIsPending = txHash => {
  var allTransactions = useAllTransactions();
  if (!txHash || !allTransactions[txHash]) return undefined;
  return !allTransactions[txHash].receipt;
};
export var useTransactionIsConfirmed = txHash => {
  var allTransactions = useAllTransactions();
  if (!txHash || !allTransactions[txHash]) return undefined;
  return Boolean(allTransactions[txHash].receipt);
};
export var useHasPendingApproval = (tokenAddress, spender) => {
  var allTransactions = useAllTransactions();
  return useMemo(() => {
    return typeof tokenAddress === 'string' && typeof spender === 'string' && Object.values(allTransactions).some(tx => {
      if (tx.receipt) return false;
      if (tx.info.type !== TransactionType.APPROVAL) return false;
      return tx.info.tokenAddress === tokenAddress && tx.info.spender === spender;
    });
  }, [allTransactions, tokenAddress, spender]);
};
export var useTransactionAdder = () => {
  var _useWeb3React2 = useWeb3React(),
      chainId = _useWeb3React2.chainId,
      account = _useWeb3React2.account;

  var dispatch = useAppDispatch();
  return useCallback((response, info) => {
    if (!account || !chainId) return;
    var hash = response.hash;
    if (!hash) throw new Error('No transaction hash found.');
    dispatch(addTransaction({
      hash,
      from: account,
      info,
      chainId
    }));
  }, [chainId, account, dispatch]);
};