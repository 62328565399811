import { useWeb3React } from '@web3-react/core';
import { isValidAddress } from "./../utils/validate";
import { getChainById } from "./../utils/chain";
import { Contract } from '@ethersproject/contracts';
import { AddressZero } from '@ethersproject/constants';
import { useMemo } from 'react';
import { captureException } from '@sentry/nextjs';
import { ABI, RewardDistributor, LONStaking } from '@tokenlon/sdk'; // import { useActiveWeb3React } from './web3'

import ERC20Instrument from "../constants/abi/ERC20.json";
import MulticallInstrument from "../constants/abi/MultiCall.json";
import ERC721_ABI from "../constants/abi/erc721.json";
import ERC1155_ABI from "../constants/abi/erc1155.json";
import MerkleRedeemAbi from "../constants/abi/MerkleRedeem.json";
import StakingRewards from "../constants/abi/StakingRewards.json";
import { RUNTIME_ENV, TokenlonEnv } from "../constants/env";
import { getAddress } from 'ethers/lib/utils';
import { Mainnet } from "../model/chain/ethereum";
var ERC20_ABI = ERC20Instrument.abi;
var MULTICALL_ABI = MulticallInstrument.abi;
export var useContract = function useContract(addressOrAddressesMap, ABI) {
  var useSinger = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId,
      account = _useWeb3React.account,
      provider = _useWeb3React.provider;

  return useMemo(() => {
    // chainId check
    if (!provider || !chainId) return null;
    if (useSinger && !account) return null;
    var address;
    if (typeof addressOrAddressesMap === 'string') address = addressOrAddressesMap;else address = addressOrAddressesMap === null || addressOrAddressesMap === void 0 ? void 0 : addressOrAddressesMap[chainId];
    if (!address || address === AddressZero) return null;

    if (!isValidAddress(address)) {
      console.error('useContract error: Invalid address');
      return null;
    }

    address = getAddress(address);

    try {
      var contract = new Contract(address, ABI, useSinger ? provider.getSigner(account) : provider);
      return contract;
    } catch (error) {
      console.error('Failed to get contract', error);
      captureException(error);
      return null;
    }
  }, [chainId, addressOrAddressesMap, ABI, useSinger, account, provider]);
};
export var useBuybackContract = () => {
  // @TODO: @izayl use `TypeChain` to create typescript type define for the contract
  return useContract(RewardDistributor, ABI.RewardDistributor);
};
export var useChain = () => {
  var _useWeb3React2 = useWeb3React(),
      chainId = _useWeb3React2.chainId;

  return useMemo(() => {
    return getChainById(chainId) || {};
  }, [chainId]);
};
export var useTokenContract = function useTokenContract(address) {
  var useSinger = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return useContract(address, ERC20_ABI, useSinger);
};
export var useLONContract = () => {
  var _useChain = useChain(),
      lonAddress = _useChain.lonAddress;

  return useTokenContract(lonAddress);
};
export var useLONToken = () => {
  var LONContract = useLONContract();
  return useMemo(() => LONContract ? {
    symbol: LONContract.symbol(),
    decimals: LONContract.decimals(),
    address: LONContract.address
  } : undefined, [LONContract]);
};
export var useXLONContract = () => {
  var _useChain2 = useChain(),
      xLonAddress = _useChain2.xLonAddress;

  return useTokenContract(xLonAddress);
};
export var useMultiCallAddress = () => {
  var _useWeb3React3 = useWeb3React(),
      chainId = _useWeb3React3.chainId;

  return useMemo(() => {
    var chain = getChainById(chainId) || Mainnet;
    return chain.multicallAddress;
  }, [chainId]);
};
export var useInterfaceMulticall = () => {
  var MULTICALL_ADDRESS = useMultiCallAddress();
  return useContract(MULTICALL_ADDRESS, MULTICALL_ABI, false);
};
export function useERC721Contract(nftAddress) {
  return useContract(nftAddress, ERC721_ABI, false);
}
export function useERC1155Contract(nftAddress) {
  return useContract(nftAddress, ERC1155_ABI, false);
}
export var useMerkleRedeemContract = () => {
  var MERKLE_REDEEM_CONTRACT = RUNTIME_ENV === TokenlonEnv.development || RUNTIME_ENV === TokenlonEnv.local ? '0xCD4252102AC626c27aCF0d839A7Ae015650BAa95' : '0x0000000006a0403952389B70d8EE4E45479023db';
  return useContract(MERKLE_REDEEM_CONTRACT, MerkleRedeemAbi);
};
export var useStakingRewardsContract = contract => {
  return useContract(contract, StakingRewards);
};
export var useLONStakingContract = () => {
  return useContract(LONStaking, ABI.LonStaking);
};