import { Env as SDKEnv } from '@tokenlon/sdk';
export var TokenlonEnv;

(function (TokenlonEnv) {
  TokenlonEnv["development"] = "development";
  TokenlonEnv["production"] = "production";
  TokenlonEnv["staging"] = "staging";
  TokenlonEnv["local"] = "local";
})(TokenlonEnv || (TokenlonEnv = {}));

export var RUNTIME_ENV = process.env.NEXT_PUBLIC_ENV || TokenlonEnv.production;
export var ENV = {
  production: RUNTIME_ENV === TokenlonEnv.production,
  development: RUNTIME_ENV === TokenlonEnv.development,
  staging: RUNTIME_ENV === TokenlonEnv.staging,
  local: RUNTIME_ENV === TokenlonEnv.local
};
export var ASSET_PREFIX = process.env.ASSET_PREFIX || '';

if (ENV.staging) {
  SDKEnv.staging();
}

console.log('RUNTIME_ENV', RUNTIME_ENV);
console.log('SDK ENV', SDKEnv.env);