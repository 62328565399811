import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
import { MediaBreakPointUp } from "../../theme";
export var StyledMenuItem = styled.div.withConfig({
  displayName: "StyledMenuItem",
  componentId: "sc-173lbpr-0"
})(["display:flex;flex-direction:column;align-items:center;font-weight:500;font-size:11px;line-height:15px;color:#9598ab;cursor:pointer;padding:20px;transition:all ease-in 0.2s;&:hover{background:#fafafc;border-radius:4px;}.icon{width:24px;margin-bottom:4px;}@media screen and (max-width:", "){padding:20px 0;}"], MediaBreakPointUp.sm);
export var MenuItem = _ref => {
  var icon = _ref.icon,
      title = _ref.title,
      _onClick = _ref.onClick;
  return __jsx(StyledMenuItem, {
    onClick: () => _onClick()
  }, __jsx("img", {
    className: "icon",
    src: icon,
    alt: "menu"
  }), __jsx("span", null, title));
};