import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { RUNTIME_ENV } from "../../constants/env";
import { addTransaction, checkedTransaction, clearAllTransactions, finalizeTransaction } from "./actions";

var now = () => new Date().getTime();

export var initialState = {};
export var transactionsReducer = createReducer(initialState, builder => {
  builder.addCase(addTransaction, (transactions, _ref) => {
    var _transactions$chainId, _transactions$chainId2;

    var _ref$payload = _ref.payload,
        chainId = _ref$payload.chainId,
        from = _ref$payload.from,
        hash = _ref$payload.hash,
        info = _ref$payload.info;

    if ((_transactions$chainId = transactions[chainId]) !== null && _transactions$chainId !== void 0 && _transactions$chainId[hash]) {
      throw Error("Transaction ".concat(hash, " already exists"));
    }

    var txs = (_transactions$chainId2 = transactions[chainId]) !== null && _transactions$chainId2 !== void 0 ? _transactions$chainId2 : {};
    txs[hash] = {
      hash,
      info,
      from,
      addTime: now()
    };
    transactions[chainId] = txs;
  }).addCase(clearAllTransactions, (transactions, _ref2) => {
    var chainId = _ref2.payload.chainId;
    if (!transactions[chainId]) return;
    transactions[chainId] = {};
  }).addCase(checkedTransaction, (transactions, _ref3) => {
    var _transactions$chainId3;

    var _ref3$payload = _ref3.payload,
        chainId = _ref3$payload.chainId,
        hash = _ref3$payload.hash,
        blockNumber = _ref3$payload.blockNumber;
    var tx = (_transactions$chainId3 = transactions[chainId]) === null || _transactions$chainId3 === void 0 ? void 0 : _transactions$chainId3[hash];
    if (!tx) return;

    if (!tx.lastCheckedBlockNumber) {
      tx.lastCheckedBlockNumber = blockNumber;
    } else {
      tx.lastCheckedBlockNumber = Math.max(blockNumber, tx.lastCheckedBlockNumber);
    }
  }).addCase(finalizeTransaction, (transactions, _ref4) => {
    var _transactions$chainId4;

    var _ref4$payload = _ref4.payload,
        chainId = _ref4$payload.chainId,
        hash = _ref4$payload.hash,
        receipt = _ref4$payload.receipt;
    var tx = (_transactions$chainId4 = transactions[chainId]) === null || _transactions$chainId4 === void 0 ? void 0 : _transactions$chainId4[hash];
    if (!tx) return;
    tx.receipt = receipt;
    tx.confirmedTime = now();
  });
});
export var transactionsPersistConfig = {
  key: "sub-transactions-".concat(RUNTIME_ENV),
  storage,
  // @note: if need to black nested keys, we should use `transforms` api instead
  // @link: https://stackoverflow.com/a/63845127/7865048 use_transforms_to_remove_nested_keys
  blacklist: []
};
export default persistReducer(transactionsPersistConfig, transactionsReducer);