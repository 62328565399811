import { ENV } from "../constants/env";
import { ArbitrumOne, ArbitrumRinkeby, Goerli, Mainnet } from "../model/chain/ethereum";
import { useLimitOrderRouter } from "./useLimitOrderRouter";
import { useNetworkValidator } from "./web3"; // 保证用于 updater 的 chainId 永远是对的，和用户的 chainId 无关

export var useUpdaterChainId = () => {
  var isLimitOrderRouter = useLimitOrderRouter();

  var _useNetworkValidator = useNetworkValidator(),
      isArbitrum = _useNetworkValidator.isArbitrum;

  var isDev = ENV.development || ENV.local;

  if (isLimitOrderRouter && isArbitrum) {
    return isDev ? ArbitrumRinkeby.chainId : ArbitrumOne.chainId;
  }

  return isDev ? Goerli.chainId : Mainnet.chainId;
};