import { Web3Provider } from '@ethersproject/providers';
import { ArbitrumOne, ArbitrumRinkeby } from "../model/chain/ethereum";
export var NETWORK_POLLING_INTERVALS = {
  [ArbitrumOne.chainId]: 1000,
  [ArbitrumRinkeby.chainId]: 1000
};
export var getLibrary = provider => {
  var chainIdOrAny = typeof provider.chainId === 'number' ? provider.chainId : typeof provider.chainId === 'string' ? parseInt(provider.chainId, 16) : 'any'; // @note: why use `any` https://github.com/ethers-io/ethers.js/issues/866

  var library = new Web3Provider(provider, chainIdOrAny);
  library.pollingInterval = 15000;
  library.detectNetwork().then(network => {
    var networkPollingInterval = NETWORK_POLLING_INTERVALS[network.chainId];

    if (networkPollingInterval) {
      console.debug('Setting polling interval', networkPollingInterval);
      library.pollingInterval = networkPollingInterval;
    }
  });
  return library;
};