import ImKeyProvider from '@imkey/web3-provider';
export class ImKeyConnect extends ImKeyProvider {
  removeListener(type, listener) {
    super.off(type, listener);
    return this;
  }

  on(type, listener) {
    if (type === 'accountsChanged') {
      super.on('accountsChanged', accounts => {
        listener(accounts.accounts);
      });
    } else {
      super.on(type, listener);
    }

    return this;
  }

}