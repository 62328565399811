export var spotSwapTokenWrapper = tokens => {
  return (tokens || []).map(item => {
    var token = {
      symbol: item.symbol,
      name: item.name,
      logo: item.logo,
      address: item.contractAddress,
      decimals: item.decimal,
      recommend: item.recommend,
      gasLimit: item.gasLimit,
      isOptimal: item.isOptimal,
      opposites: item.opposites,
      maxTradeAmount: item.maxTradeAmount,
      minTradeAmount: item.minTradeAmount,
      precision: item.precision
    };
    return token;
  });
};
export var toSpotSwapToken = t => {
  var token = {
    symbol: t.symbol,
    name: t.name,
    logo: t.logo,
    address: t.contractAddress,
    decimals: t.decimal,
    recommend: t.recommend,
    gasLimit: t.gasLimit,
    isOptimal: t.isOptimal,
    opposites: t.opposites,
    maxTradeAmount: t.maxTradeAmount,
    minTradeAmount: t.minTradeAmount,
    precision: t.precision
  };
  return token;
};
/**
 * @warning only for deprecated code
 *
 * the new logic should use `spotSwapToken` or `limitSwapToken` instead
 */

export var tokenlonTokensWrapper = spotSwapTokens => {
  return (spotSwapTokens || []).map(tokenlonTokenWrapper);
};
export var tokenlonTokenWrapper = spotSwapToken => {
  var token = {
    symbol: spotSwapToken.symbol,
    name: spotSwapToken.name,
    logo: spotSwapToken.logo,
    gasLimit: spotSwapToken.gasLimit,
    contractAddress: spotSwapToken.address,
    decimal: spotSwapToken.decimals,
    isOptimal: spotSwapToken.isOptimal,
    recommend: spotSwapToken.recommend,
    opposites: spotSwapToken.opposites,
    maxTradeAmount: spotSwapToken.maxTradeAmount,
    minTradeAmount: spotSwapToken.minTradeAmount,
    precision: spotSwapToken.precision
  };
  return token;
};
export var limitSwapTokenWrapper = tokens => {
  return (tokens || []).map(item => {
    var token = {
      symbol: item.symbol,
      name: item.name,
      logo: item.logoURI,
      address: item.address,
      decimals: item.decimals,
      blocked: item.displayType == -1,
      displayType: item.displayType,
      recommend: item.recommend
    };
    return token;
  });
}; // reference from ~/components/Exchange/helpers

export var findTokenByTokenSymbol = (tokens, tokenSymbol) => tokens === null || tokens === void 0 ? void 0 : tokens.find(_ref => {
  var symbol = _ref.symbol;
  return symbol === tokenSymbol;
});
export var getOppositesByTokenSymbol = (tokens, tokenSymbol) => {
  var _opposites$map;

  var token = findTokenByTokenSymbol(tokens, tokenSymbol);
  var opposites = token === null || token === void 0 ? void 0 : token.opposites;
  return opposites === null || opposites === void 0 || (_opposites$map = opposites.map(symbol => findTokenByTokenSymbol(tokens, symbol))) === null || _opposites$map === void 0 ? void 0 : _opposites$map.filter(token => !!token);
};
export var getOppositesByToken = (tokens, token) => getOppositesByTokenSymbol(tokens, token === null || token === void 0 ? void 0 : token.symbol);
export var findTokenByAddress = (tokens, address) => tokens.find(token => token.contractAddress.toLowerCase() === address.toLowerCase());