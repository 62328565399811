import { useWeb3React } from '@web3-react/core';
import { useUnstoppedDomainName } from "./useUnstoppedDomainName";
export var useDomainName = () => {
  var _useWeb3React = useWeb3React(),
      ENSName = _useWeb3React.ENSName;

  var unstoppableDomainName = useUnstoppedDomainName();
  return [unstoppableDomainName, {
    name: ENSName
  }].find(domain => Boolean(domain.name)) || {
    name: ''
  };
};