import _extends from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["logo", "symbol"];
var __jsx = React.createElement;
import React from 'react';
import { ImageLogo, TextLogo } from "./styled";

var TokenLogo = _ref => {
  var logo = _ref.logo,
      symbol = _ref.symbol,
      logoProps = _objectWithoutProperties(_ref, _excluded);

  return logo ? __jsx(ImageLogo, _extends({}, logoProps, {
    src: logo
  })) : __jsx(TextLogo, logoProps, (symbol || '').substr(0, 4));
};

export default TokenLogo;