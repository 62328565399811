import { useWeb3React } from '@web3-react/core';
import TokenWebView from '@consenlabs-fe/webview';
import { ImKeyConnect } from "../connection/ImKey";
export function useIsIMWallet() {
  var _innerProvider$sessio;

  var _useWeb3React = useWeb3React(),
      provider = _useWeb3React.provider;

  if (!provider) {
    return false;
  }

  var isIMWebView = TokenWebView.isTokenEnv();
  var innerProvider = provider.provider; // @ts-ignore

  var isIMWalletConnect = innerProvider === null || innerProvider === void 0 || (_innerProvider$sessio = innerProvider.session) === null || _innerProvider$sessio === void 0 || (_innerProvider$sessio = _innerProvider$sessio.peer) === null || _innerProvider$sessio === void 0 || (_innerProvider$sessio = _innerProvider$sessio.metadata) === null || _innerProvider$sessio === void 0 ? void 0 : _innerProvider$sessio.name.includes('imToken'); // @ts-ignore

  var isImKeyProvider = innerProvider instanceof ImKeyConnect && (innerProvider === null || innerProvider === void 0 ? void 0 : innerProvider.hasOwnProperty('imKeyResponsiveEmitter')) && typeof (innerProvider === null || innerProvider === void 0 ? void 0 : innerProvider.imKeyResponsiveEmitter) === 'function';
  return isIMWebView || isIMWalletConnect || isImKeyProvider;
}