import { Connector } from '@web3-react/types';
import { CONNECTIONS } from '.';
import { isMetaMaskWallet } from "./detect";
import { ConnectionType } from "./type";
export function getConnection(c) {
  if (c instanceof Connector) {
    var _connection = CONNECTIONS.find(connection => connection.connector === c);

    if (!_connection) {
      throw new Error('Unsupported connector');
    }

    return _connection;
  }

  var connection = CONNECTIONS.find(connection => connection.type === c);
  return connection;
}
export function getConnectionName(connectionType) {
  switch (connectionType) {
    case ConnectionType.Injected:
      return isMetaMaskWallet() ? 'MetaMask' : 'Browser Wallet';

    case ConnectionType.WalletConnectV1:
      return 'WalletConnect V1';

    case ConnectionType.WalletConnectV2:
      return 'WalletConnect';

    case ConnectionType.Coinbase:
      return 'Coinbase Wallet';

    case ConnectionType.GnosisSafe:
      return 'Gnosis Safe';

    case ConnectionType.Network:
      return 'Network';
  }
}