import { isClient } from "./userAgent"; // all host path ends with '/'

export var host = {
  get origin() {
    if (!isClient) return '/';
    var origin = window.location.origin;
    if (origin.endsWith('/')) return origin;
    return "".concat(origin, "/");
  },

  get href() {
    if (!isClient) return '/';
    var host = window.location.href;
    if (host.endsWith('/')) return host;
    return "".concat(host, "/");
  }

};