import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { networkConnection } from "../../connection";
import { useIsWindowVisible } from "../../hooks/useIsWindowVisible";
import useUpdaterByBlock from "../../hooks/useUpdaterByBlock";
import { useAppDispatch } from "../hooks";
import { updateBlockNumber, updateChainId, updateFeeData } from "./reducer";
import { getBlocksPerFetchForChainId } from "../../utils/getBlocksPerFetchForChainId";
export var Updater = () => {
  useNetworkConnectorUpdater();
  useFeeDataUpdater();
  useBlockNumberUpdater();
  var dispatch = useAppDispatch();

  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId;

  useEffect(() => {
    dispatch(updateChainId({
      chainId
    }));
  }, [dispatch, chainId]);
  return null;
};
export var useBlockNumberUpdater = () => {
  var windowVisible = useIsWindowVisible();
  var dispatch = useAppDispatch();

  var _useWeb3React2 = useWeb3React(),
      activeChainId = _useWeb3React2.chainId,
      provider = _useWeb3React2.provider;

  var _useState = useState({
    chainId: activeChainId
  }),
      blockNumber = _useState[0].blockNumber,
      setChainBlock = _useState[1];

  var onBlock = useCallback(block => {
    setChainBlock(chainBlock => {
      if (chainBlock.chainId === activeChainId || !chainBlock.chainId) {
        var newChainBlock = {
          blockNumber: Math.max(chainBlock.blockNumber || 0, block),
          chainId: activeChainId
        };
        dispatch(updateBlockNumber(newChainBlock));
        return newChainBlock;
      }

      dispatch(updateBlockNumber(chainBlock));
      return chainBlock;
    });
  }, [activeChainId, setChainBlock]);
  useEffect(() => {
    var stale = false;

    if (provider && activeChainId && windowVisible) {
      // reset
      setChainBlock(chainBlock => {
        return chainBlock.chainId === activeChainId ? chainBlock : {
          chainId: activeChainId
        };
      });
      provider.getBlockNumber().then(block => {
        if (!stale) onBlock(block);
      }).catch(error => {
        console.error("Failed to get block number for chainId: ".concat(activeChainId), error);
      });
      provider.on('block', onBlock);
      return () => {
        stale = true;
        provider.removeListener('block', onBlock);
      };
    }
  }, [activeChainId, onBlock, windowVisible, provider]);
  return blockNumber;
};
export var useFeeDataUpdater = () => {
  var _useWeb3React3 = useWeb3React(),
      provider = _useWeb3React3.provider,
      chainId = _useWeb3React3.chainId;

  var windowVisible = useIsWindowVisible();
  var dispatch = useAppDispatch();
  var blocksPerFetch = useMemo(() => getBlocksPerFetchForChainId(chainId), [chainId]);
  var feeDataCallback = useCallback(() => {
    if (provider && windowVisible) {
      provider.getFeeData().then(data => {
        dispatch(updateFeeData({
          chainId,
          feeData: {
            gasPrice: data.gasPrice.toString(),
            maxFeePerGas: data.maxFeePerGas.toString(),
            maxPriorityFeePerGas: data.maxPriorityFeePerGas.toString()
          }
        }));
      }).catch(err => {
        console.error(err);
      });
    }
  }, [provider, chainId, windowVisible]);
  useUpdaterByBlock(feeDataCallback, blocksPerFetch);
};
export var useNetworkConnectorUpdater = () => {
  var _useWeb3React4 = useWeb3React(),
      chainId = _useWeb3React4.chainId,
      connector = _useWeb3React4.connector;

  useEffect(() => {
    if (chainId && connector !== networkConnection.connector) {
      networkConnection.connector.activate(chainId);
    }
  }, [chainId, connector]);
};
export default Updater;