import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import dayjs from 'dayjs';
import { TokenlonEnv } from "../../constants/env";
import { API_ROOT_DOMAIN } from "../../constants/rootDomain";
import { RPC } from "./rpc"; // @todo 需要讨论这里是用 env 还是 chainId

export var ExchangeEndpoint = {
  [TokenlonEnv.local]: 'https://exchange.imdev.works',
  [TokenlonEnv.development]: 'https://exchange.imdev.works',
  [TokenlonEnv.staging]: 'https://exchange.imstaging.works',
  [TokenlonEnv.production]: 'https://exchange.tokenlon.im'
};
export class ExchangeRPC extends RPC {
  constructor() {
    super({
      envEndpoints: ExchangeEndpoint
    });
  }

  getMobileAppConfig() {
    return this.rpc('/rpc', 'tokenlon.getMobileAppConfig');
  }

}
export class MarketRPC extends RPC {
  constructor() {
    super({
      endpoint: "https://api.".concat(API_ROOT_DOMAIN, "/v1/market")
    });

    _defineProperty(this, "requestPool", new Map());
  }

  getMarketPrice() {
    var tokensAddress = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var chainId = arguments.length > 1 ? arguments[1] : undefined;
    var currency = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'USD';
    if (!tokensAddress || !tokensAddress.length) return Promise.resolve([]);
    var key = [...tokensAddress, chainId, currency].join();
    var requestInPool = this.requestPool.get(key);

    if (requestInPool) {
      if (requestInPool.isPending) {
        return requestInPool.p;
      }

      if (dayjs().diff(requestInPool.timestamp) < 500) {
        return requestInPool.p;
      }

      this.requestPool.delete(key);
    }

    var params = tokensAddress.map(address => {
      return chainId ? {
        caip2: "eip155:".concat(chainId),
        address,
        showOption: 'always'
      } : {
        chainType: 'ETHEREUM',
        showOption: 'always',
        address
      };
    });
    if (!params.length) return Promise.resolve([]);
    var p = this.rpc('/', 'market.getPrices', params, {
      headers: {
        'X-CURRENCY': currency
      }
    }).then(res => {
      try {
        var r = this.requestPool.get(key);
        if (r) r.isPending = false;
      } catch (error) {// ignore
      }

      return res;
    }).catch(error => {
      try {
        var r = this.requestPool.get(key);
        if (r) r.isPending = false;
      } catch (error) {// ignore
      }

      throw error;
    });
    this.requestPool.set(key, {
      isPending: true,
      p,
      timestamp: +new Date()
    });
    return p;
  }

}
export var exchangeRpc = new ExchangeRPC();
export var marketRpc = new MarketRPC();
export default exchangeRpc;