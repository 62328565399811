import { WalletConnect } from '@web3-react/walletconnect';
import { ALL_SUPPORTED_RPC_URLS } from "../constants/chain";
export class WalletConnectV1 extends WalletConnect {
  constructor(_ref) {
    var actions = _ref.actions,
        onError = _ref.onError,
        _ref$qrcode = _ref.qrcode,
        qrcode = _ref$qrcode === void 0 ? true : _ref$qrcode;
    super({
      actions,
      options: {
        qrcode,
        rpc: ALL_SUPPORTED_RPC_URLS
      },
      onError
    });
  }

  activate(chainId) {
    return super.activate(chainId);
  }

}