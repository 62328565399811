import { useState } from 'react';

/**
 * 状态切换 Custom Hook
 * @param initial [boolean] 初始状态
 */
export var useSwitch = initial => {
  var _useState = useState(initial),
      sw = _useState[0],
      setSw = _useState[1];

  var on = () => setSw(true);

  var off = () => setSw(false);

  var toggle = () => setSw(!sw);

  return {
    sw,
    toggle,
    on,
    off
  };
};