var LOCK_CLASS_NAME = 'lock-body';

var injectCss = () => {
  var TagId = 'tokenlon-lock-body';
  var injectedCSS = document.getElementById(TagId);

  if (!injectedCSS) {
    var injectedEl = document.createElement('style');
    injectedEl.setAttribute('id', TagId);
    injectedEl.innerHTML = "\n      html.".concat(LOCK_CLASS_NAME, " {\n        overflow-y: hidden !important;\n        position: fixed;\n        width: 100%;\n      }\n    ");
    document.head.appendChild(injectedEl);
  }
};

var lock = () => {
  document.documentElement.style.top = -document.scrollingElement.scrollTop + 'px';
  document.documentElement.classList.add(LOCK_CLASS_NAME);
};

var relieveLock = () => {
  document.documentElement.classList.remove(LOCK_CLASS_NAME);
  document.scrollingElement.scrollTop = -parseInt(document.documentElement.style.top, 10);
  document.documentElement.style.top = '';
};

export var lockBody = lockBody => {
  injectCss();
  lockBody ? lock() : relieveLock();
};