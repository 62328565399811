import styled from 'styled-components';
export var StyledAllowanceManageItemWrapper = styled.div.withConfig({
  displayName: "StyledAllowanceManageItemWrapper",
  componentId: "sc-ihxs0x-0"
})(["display:flex;align-items:center;.manage-item-left{padding:0 8px;}.manage-item-logo{width:50px;height:50px;display:inline-block;margin-right:10px;margin-left:10px;}"]);
export var StyledManageItemRight = styled.div.withConfig({
  displayName: "StyledManageItemRight",
  componentId: "sc-ihxs0x-1"
})(["flex:1;display:flex;justify-content:space-between;border-bottom:0.5px solid #eaecf6;padding:14.5px 0;padding-right:20.5px;.manage-item-symbol{font-weight:normal;font-size:16px;line-height:27px;color:#2c2842;}.manage-item-switch{margin-left:auto;}"]);
export var StyledManageList = styled.div.withConfig({
  displayName: "StyledManageList",
  componentId: "sc-ihxs0x-2"
})(["padding-top:10px;overflow-y:auto;max-height:70vh;"]);
export var StyledMenuItem = styled.div.withConfig({
  displayName: "StyledMenuItem",
  componentId: "sc-ihxs0x-3"
})(["display:flex;flex-direction:column;align-items:center;font-weight:500;font-size:11px;line-height:15px;color:#9598ab;cursor:pointer;padding:20px;transition:all ease-in 0.2s;&:hover{background:#fafafc;border-radius:4px;}.icon{width:24px;margin-bottom:4px;}"]);
export var StyledTokenInfo = styled.div.withConfig({
  displayName: "StyledTokenInfo",
  componentId: "sc-ihxs0x-4"
})(["background:#fafafc;border:1px solid #eff0fa;box-sizing:border-box;border-radius:48px;padding:16px 15px;display:flex;align-items:center;margin-bottom:10px;.logo{width:28px;margin-right:10px;}.symbol{color:#000000;font-size:15px;line-height:24px;display:flex;flex-direction:column;}.info{margin-top:2px;color:#9598ab;font-size:11px;line-height:15px;}.asset{margin-left:auto;display:flex;flex-direction:column;text-align:right;}.balance{color:#5f4dbc;font-weight:500;font-size:15px;line-height:21px;}.value{color:#9598ab;font-weight:500;font-size:11px;line-height:15px;}"]);
export var StyledInfoContent = styled.div.withConfig({
  displayName: "StyledInfoContent",
  componentId: "sc-ihxs0x-5"
})([".addr-title{color:#9598ab;font-weight:500;font-size:13px;line-height:18px;margin:0;text-align:left;}.content{padding:25px 20px;}.addr-info{display:flex;justify-content:space-between;align-items:center;flex-wrap:nowrap;margin-top:5px;margin-bottom:18px;cursor:pointer;}.addr{font-weight:500;font-size:15px;line-height:18px;color:#191c1e;overflow-wrap:break-word;overflow:hidden;}.logo-copy{margin-left:28px;box-sizing:border-box;width:33px;}.footer{box-shadow:inset 0px 1px 0px rgba(0,0,0,0.05);display:flex;justify-content:space-between;padding:5px 20px 5px;}"]);