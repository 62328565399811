export var ConnectionType;

(function (ConnectionType) {
  ConnectionType["ImTokenCallApp"] = "imToken Call App";
  ConnectionType["ImTokenWalletConnect"] = "imToken Wallet Connect";
  ConnectionType["Injected"] = "Injected";
  ConnectionType["Network"] = "Network";
  ConnectionType["WalletConnectV1"] = "WalletConnect V1";
  ConnectionType["WalletConnectV2"] = "WalletConnect V2";
  ConnectionType["GnosisSafe"] = "Gnosis Safe";
  ConnectionType["ImKey"] = "ImKey";
  ConnectionType["UnstoppableDomain"] = "Unstoppable Domain";
  ConnectionType["Coinbase"] = "Coinbase";
})(ConnectionType || (ConnectionType = {}));