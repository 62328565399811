import styled, { css } from 'styled-components';
export var StyledSwitchButton = styled.button.withConfig({
  displayName: "StyledSwitchButton",
  componentId: "sc-16kysv2-0"
})(["margin:0;padding:0;color:rgba(0,0,0,0.65);font-size:14px;font-variant:tabular-nums;line-height:1.5;list-style:none;-webkit-font-feature-settings:'tnum';font-feature-settings:'tnum';position:relative;display:inline-block;-webkit-box-sizing:border-box;box-sizing:border-box;min-width:44px;height:22px;line-height:20px;vertical-align:middle;background:rgba(0,0,0,0.1);border:1px solid transparent;border-radius:100px;cursor:pointer;-webkit-transition:all 0.36s;transition:all 0.36s;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;&,&:hover,&:focus{outline:none;}", " ", ""], _ref => {
  var active = _ref.active;
  return active && css(["background:#5e4dbc;"]);
}, _ref2 => {
  var disabled = _ref2.disabled;
  return disabled && css(["cursor:not-allowed;opacity:0.4;"]);
});
export var StyledSwitchDot = styled.span.withConfig({
  displayName: "StyledSwitchDot",
  componentId: "sc-16kysv2-1"
})(["position:absolute;top:1px;left:1px;width:18px;height:18px;background-color:#fff;border-radius:18px;cursor:pointer;-webkit-transition:all 0.36s cubic-bezier(0.78,0.14,0.15,0.86);transition:all 0.36s cubic-bezier(0.78,0.14,0.15,0.86);box-shadow:0 2px 4px 0 rgba(0,35,11,0.2);", " ", ""], _ref3 => {
  var active = _ref3.active;
  return active && css(["left:60%;margin-left:-1px;"]);
}, _ref4 => {
  var disabled = _ref4.disabled;
  return disabled && css(["cursor:not-allowed;"]);
});
export var StyledSwitchLoading = styled.i.withConfig({
  displayName: "StyledSwitchLoading",
  componentId: "sc-16kysv2-2"
})(["color:#5e4dbc;position:absolute;top:1px;left:1px;width:18px;height:18px;background-color:#fff;border-radius:18px;cursor:pointer;-webkit-transition:all 0.36s cubic-bezier(0.78,0.14,0.15,0.86);transition:all 0.36s cubic-bezier(0.78,0.14,0.15,0.86);content:' ';display:inline-block;-webkit-animation:switchCircle 1s infinite linear;animation:switchCircle 1s infinite linear;&::before{display:inline-block;-webkit-animation:switchCircle 1s infinite linear;animation:switchCircle 1s infinite linear;}@-webkit-keyframes switchCircle{100%{-webkit-transform:rotate(360deg);transform:rotate(360deg);}}@keyframes switchCircle{100%{-webkit-transform:rotate(360deg);transform:rotate(360deg);}}", " ", ""], _ref5 => {
  var active = _ref5.active;
  return active && css(["left:60%;margin-left:-1px;"]);
}, _ref6 => {
  var disabled = _ref6.disabled;
  return disabled && css(["cursor:not-allowed;"]);
});