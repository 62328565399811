import { useEffect } from 'react';
export var useClickAway = (ref, handler) => {
  useEffect(() => {
    var callback = event => {
      var el = ref.current;
      if (!event || !el || el.contains(event.target)) return;
      handler(event);
    };

    if (typeof document === 'undefined') return;
    document.addEventListener('click', callback, true);
    return () => document.removeEventListener('click', callback, true);
  }, [ref, handler]);
};
export default useClickAway;