import { createAction } from '@reduxjs/toolkit';
// @todo more txInfo support
// | BasicSwapTransactionInfo
// | ExactInputSwapTransactionInfo
// | ExactOutputSwapTransactionInfo
// | BuybackTransactionInfo
// | AddLiquidTransactionInfo
// | RemoveLiquidTransactionInfo
export var TransactionType;

(function (TransactionType) {
  TransactionType[TransactionType["APPROVAL"] = 0] = "APPROVAL";
})(TransactionType || (TransactionType = {}));

export var addTransaction = createAction('transactions/addTransaction');
export var checkedTransaction = createAction('transactions/checkedTransaction');
export var clearAllTransactions = createAction('transactions/clearAllTransactions');
export var finalizeTransaction = createAction('transactions/finalizeTransaction');