import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useMemo } from 'react';
import MD5 from 'md5.js';
import { useWeb3React } from '@web3-react/core'; // Google Analytics callback hook
// when to useGA hook: the submit info contain chainInfo(address/chainId)

export var useGA = () => {
  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId,
      account = _useWeb3React.account;

  var ga = useMemo(() => {
    var createGAFn = type => function (eventName) {
      var eventParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      window.gtag('event', eventName, _objectSpread({
        event_category: type,
        actionType: type,
        caip2: chainId ? "eip155:".concat(chainId) : undefined,
        wallet_address: new MD5().update("".concat(account)).digest('hex')
      }, eventParams));
    };

    var trackClick = createGAFn('click');

    var trackElClick = event => {
      var currentTarget = event.currentTarget;

      if (currentTarget instanceof HTMLElement || currentTarget instanceof SVGElement) {
        var dataset = currentTarget.dataset;

        if (dataset && dataset.trackid) {
          var params = {};

          try {
            params = JSON.parse(dataset.trackdata) || {};
          } catch (e) {}

          ga.trackClick(dataset.trackid, params);
        }
      }
    };

    var trackElClickCurring = event => {
      trackElClick(event);
      return fn => fn(event);
    };

    return {
      trackClick,
      trackElClick,
      trackElClickCurring,
      trackView: createGAFn('view'),
      trackStatus: createGAFn('status')
    };
  }, [account, chainId]);
  return ga;
};