import { getAddress } from 'ethers/lib/utils';
import { AddressZero } from '@ethersproject/constants';
import { useMemo } from 'react';
import { NEVER_RELOAD, useSingleCallResult } from "../state/multicall/hooks";
import { useTokenContract } from "./useContract";
import { useNativeCurrencySymbol } from "../state/wallet/hooks";
import { useWeb3React } from '@web3-react/core';
export var useToken = function useToken(uncheckedAddress) {
  var useSinger = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var address = useMemo(() => uncheckedAddress ? getAddress(uncheckedAddress) : undefined, [uncheckedAddress]);
  var nativeSymbol = useNativeCurrencySymbol();

  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId; // @todo get from state/dex cache
  // const tokens = useToken()


  var tokenContract = useTokenContract(address, useSinger);
  var symbol = useSingleCallResult(tokenContract, 'symbol', undefined, NEVER_RELOAD);
  var name = useSingleCallResult(tokenContract, 'name', undefined, NEVER_RELOAD);
  var decimals = useSingleCallResult(tokenContract, 'decimals', undefined, NEVER_RELOAD);
  var anyLoading = useMemo(() => {
    return [symbol, decimals, name].some(_ref => {
      var loading = _ref.loading;
      return loading == true;
    });
  }, [symbol, decimals, name]);
  return useMemo(() => {
    if (address === AddressZero) {
      return {
        address,
        name: 'Ether',
        symbol: nativeSymbol,
        decimals: 18
      };
    }

    if (!tokenContract) return undefined;
    if (anyLoading) return undefined;

    if (symbol.result && decimals.result) {
      var _name$result, _symbol$result, _decimals$result;

      return {
        address,
        name: (_name$result = name.result) === null || _name$result === void 0 ? void 0 : _name$result[0],
        symbol: (_symbol$result = symbol.result) === null || _symbol$result === void 0 ? void 0 : _symbol$result[0],
        decimals: (_decimals$result = decimals.result) === null || _decimals$result === void 0 ? void 0 : _decimals$result[0]
      };
    }

    return undefined;
  }, [address, anyLoading, symbol.result, decimals.result, tokenContract, chainId]);
};