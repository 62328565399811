import { WalletConnect } from '@web3-react/walletconnect-v2';
import { ALL_SUPPORTED_CHAIN_IDS, ALL_SUPPORTED_WC_RPC_URLS } from "../constants/chain";
export class WalletConnectV2 extends WalletConnect {
  constructor(_ref) {
    var actions = _ref.actions,
        defaultChainId = _ref.defaultChainId,
        onError = _ref.onError,
        _ref$qrcode = _ref.qrcode,
        qrcode = _ref$qrcode === void 0 ? true : _ref$qrcode;
    super({
      actions,
      options: {
        projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
        chains: [defaultChainId],
        optionalChains: ALL_SUPPORTED_CHAIN_IDS,
        showQrModal: qrcode,
        rpcMap: ALL_SUPPORTED_WC_RPC_URLS,
        optionalMethods: ['eth_sendTransaction', 'eth_sendRawTransaction', 'eth_signTransaction', 'personal_sign', 'eth_signTypedData', 'eth_signTypedData_v4', 'eth_sign'],
        qrModalOptions: {
          themeMode: 'light',
          themeVariables: {
            '--w3m-z-index': '9999',
            '--wcm-z-index': '9999'
          }
        }
      },
      onError
    });
  }

  activate(chainId) {
    return super.activate(chainId);
  }

}