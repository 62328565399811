import { ArbitrumOne, ArbitrumRinkeby, Goerli, Mainnet } from "../model/chain/ethereum";
import { AddressZero } from '@ethersproject/constants';
export var ENS_REGISTRAR_ADDRESSES = {
  [Mainnet.chainId]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [Goerli.chainId]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
};
export var WETH = {
  [Mainnet.chainId]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  [Goerli.chainId]: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
  [ArbitrumOne.chainId]: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  [ArbitrumRinkeby.chainId]: '0xEBbc3452Cc911591e4F18f3b36727Df45d6bd1f9'
};
export var USDC = {
  [Mainnet.chainId]: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  [Goerli.chainId]: '0x07865c6e87b9f70255377e024ace6630c1eaa37f',
  [ArbitrumOne.chainId]: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
  [ArbitrumRinkeby.chainId]: '0x8dA45cf09A24B8fc94C522a2e7e09EE3426B3212'
};
export var ARB = {
  [ArbitrumOne.chainId]: '0x912CE59144191C1204E64559FE8253a0e49E6548',
  [Mainnet.chainId]: '0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1'
};
export var ETH = {
  [Mainnet.chainId]: AddressZero,
  [Goerli.chainId]: AddressZero,
  [ArbitrumOne.chainId]: AddressZero
};
export var USDT = {
  [Mainnet.chainId]: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  [Goerli.chainId]: '0xa93ef9215b907c19e739e2214e1aa5412a0401b5',
  [ArbitrumOne.chainId]: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9'
};
export var LON = {
  [Mainnet.chainId]: '0x0000000000095413afC295d19EDeb1Ad7B71c952',
  [Goerli.chainId]: '0x6dA0e6ABd44175f50C563cd8b860DD988A7C3433',
  [ArbitrumOne.chainId]: '0x55678cd083fcDC2947a0Df635c93C838C89454A3',
  [ArbitrumRinkeby.chainId]: '0x3828E007B7413Be83550AB520f706cC1773464DB'
};
export var permit2Address = {
  1: '0x000000000022d473030f116ddee9f6b43ac78ba3',
  5: '0x000000000022d473030f116ddee9f6b43ac78ba3'
};