export var LimitOrderSubmitStatus;

(function (LimitOrderSubmitStatus) {
  LimitOrderSubmitStatus["Idle"] = "Idle";
  LimitOrderSubmitStatus["SignOrderPending"] = "SignOrderPending";
  LimitOrderSubmitStatus["SignOrderSuccess"] = "SignOrderSuccess";
  LimitOrderSubmitStatus["SignOrderFailure"] = "SignOrderFailure";
  LimitOrderSubmitStatus["CancelOrderPending"] = "CancelOrderPending";
  LimitOrderSubmitStatus["CancelOrderRejected"] = "CancelOrderRejected";
  LimitOrderSubmitStatus["CancelOrderSuccess"] = "CancelOrderSuccess";
  LimitOrderSubmitStatus["CancelOrderFailure"] = "CancelOrderFailure";
  LimitOrderSubmitStatus["SignOrderFailureCode1001"] = "sign_limit_order_failed_1001";
  LimitOrderSubmitStatus["SignOrderFailureCode1002"] = "sign_limit_order_failed_1002";
  LimitOrderSubmitStatus["SignOrderFailureCode1003"] = "sign_limit_order_failed_1003";
  LimitOrderSubmitStatus["SignOrderFailureCode1004"] = "sign_limit_order_failed_1004";
  LimitOrderSubmitStatus["SignOrderFailureCode1005"] = "sign_limit_order_failed_1005";
  LimitOrderSubmitStatus["SignOrderFailureCode1006"] = "sign_limit_order_failed_1006";
  LimitOrderSubmitStatus["SignOrderFailureCode1007"] = "sign_limit_order_failed_1007";
  LimitOrderSubmitStatus["SignOrderFailureCode1008"] = "sign_limit_order_failed_1008";
  LimitOrderSubmitStatus["SignOrderFailureCode1009"] = "sign_limit_order_failed_1009";
  LimitOrderSubmitStatus["SignOrderFailureCode1010"] = "sign_limit_order_failed_1010";
  LimitOrderSubmitStatus["SignOrderFailureCode1011"] = "sign_limit_order_failed_1011";
  LimitOrderSubmitStatus["SignOrderFailureCode1012"] = "sign_limit_order_failed_1012";
  LimitOrderSubmitStatus["SignOrderFailureCode1013"] = "sign_limit_order_failed_1013";
  LimitOrderSubmitStatus["SignOrderFailureCode1014"] = "sign_limit_order_failed_1014";
  LimitOrderSubmitStatus["SignOrderFailureCode1015"] = "sign_limit_order_failed_1015";
  LimitOrderSubmitStatus["SignOrderFailureCode1016"] = "sign_limit_order_failed_1016";
})(LimitOrderSubmitStatus || (LimitOrderSubmitStatus = {}));

export var LimitOrderExpiration;

(function (LimitOrderExpiration) {
  LimitOrderExpiration["Min5"] = "5_minutes";
  LimitOrderExpiration["Min20"] = "20_minutes";
  LimitOrderExpiration["Hour1"] = "1_hour";
  LimitOrderExpiration["Hour12"] = "12_hours";
  LimitOrderExpiration["Day1"] = "1_day";
  LimitOrderExpiration["Day3"] = "3_days";
  LimitOrderExpiration["Day7"] = "7_days";
  LimitOrderExpiration["Week2"] = "2_weeks";
  LimitOrderExpiration["Month6"] = "6_months";
})(LimitOrderExpiration || (LimitOrderExpiration = {}));

export var SpotSwapUserTradeStatus;

(function (SpotSwapUserTradeStatus) {
  SpotSwapUserTradeStatus["NORMAL"] = "normal";
  SpotSwapUserTradeStatus["INQUIRING"] = "inquiring";
  SpotSwapUserTradeStatus["PREVIEWING_ORDER"] = "previewing_order";
  SpotSwapUserTradeStatus["ORDER_IN_PROGRESS"] = "order_in_progress";
  SpotSwapUserTradeStatus["ORDER_COMPLETED"] = "order_completed";
  SpotSwapUserTradeStatus["ORDER_FAILED"] = "order_failed";
})(SpotSwapUserTradeStatus || (SpotSwapUserTradeStatus = {}));