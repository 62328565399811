import { ArbitrumOne, ArbitrumRinkeby } from "../model/chain/ethereum"; // Please update in time according to block time. https://dune.com/jacobdcastro/avg-block-times

export var getBlocksPerFetchForChainId = chainId => {
  switch (chainId) {
    case ArbitrumOne.chainId:
      return 24;

    case ArbitrumRinkeby.chainId:
      return 24;

    default:
      return 1;
  }
};