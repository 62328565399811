var __jsx = React.createElement;
import React, { useEffect, useState } from 'react';
import { StyledSwitchButton, StyledSwitchDot, StyledSwitchLoading } from "./styled";

var Switch = _ref => {
  var checked = _ref.checked,
      onChange = _ref.onChange,
      _ref$loading = _ref.loading,
      loading = _ref$loading === void 0 ? false : _ref$loading;

  var _useState = useState(checked),
      active = _useState[0],
      setActive = _useState[1];

  var clickHandler = event => {
    if (loading) return;
    onChange && onChange(event);
  };

  useEffect(() => {
    if (checked === undefined) return;
    setActive(checked);
  }, [checked]);
  return __jsx(StyledSwitchButton, {
    active: active,
    disabled: loading,
    onClick: clickHandler
  }, __jsx(StyledSwitchDot, {
    active: active,
    disabled: loading
  }), loading && __jsx(StyledSwitchLoading, {
    active: active,
    disabled: loading
  }, __jsx("svg", {
    viewBox: "0 0 1024 1024",
    focusable: "false",
    className: "spin",
    width: "1em",
    height: "1em",
    fill: "currentColor",
    "aria-hidden": "true"
  }, __jsx("path", {
    d: "M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"
  }))));
};

export default Switch;